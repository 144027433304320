import M4CRichTextEditor from '@m4c/matgen/rte.js'
// import SectionLoader from '../../-components/objects/SectionLoader/index.js';
import DOMPurify from 'dompurify'
import { v4 as UUID } from 'uuid'
import {
  authHeaderOpts,
  isJsonAnswer,
  requireAuth,
} from '../../+auth-global/data/index.js'

import {
  studySelect,
  loadStudySelect,
  RichTextForm,
  killModal,
  mainContentLoader,
  mainContentLoaderStop,
  richTextContainer,
  saveStudyData,
} from '../../+auth-global/display.js'
import {
  debouncedrichTextEditorUpdate,
  initQuillOutput,
  loadRichTextFonts,
  richTextEditorPreview,
} from '../../-components/objects/RichTextEditor.js'
import {
  createStudyMaterialRecord,
  materialGAEvents,
  pageGAEvents,
  saveMaterialPage,
  saveMaterialRecord,
} from './data.js'
import { richTextLoader, richTextLoaderStop } from './display.js'

const cancelRichText = qid => {
  M4CGlobal.quill[qid].setContents(JSON.parse(MatgenGlobal.saveRichTextRestore))
  document.querySelector('#quill-output').innerHTML =
    M4CGlobal.quill[qid].root.innerHTML
  debouncedrichTextEditorUpdate({
    id: qid,
    cb: () => {
      // console.error('CLOSE RT MODAL');
      killModal('#editor-rich-text-modal')
      $('#quill-ouput-wrapper').remove()
      $('#matgen-sidebar').css('opacity', 1)
      delete M4CGlobal.quill[qid]
      delete MatgenGlobal.previewEditor
    },
  })
}

export const createNewMaterial = ({
  study_id,
  template_id,
  question_id,
  next_page_id,
  redir = true,
} = {}) => {
  createStudyMaterialRecord({
    study_id,
    template_id,
    cb: async ({ response, id }) => {
      console.log(response)
      MatgenGlobal.promptSaveClicked = false
      killModal('#prompt-form-modal')
      $('#loader-message').text('Saving material record...')
      if (question_id) {
        await saveStudyData({
          id: UUID(),
          user_folder_id: study_id || '0',
          question_id,
          answer: JSON.stringify({
            html: M4CGlobal.quill[question_id].root.innerHTML,
            plain: M4CGlobal.quill[question_id].getText(),
            data: M4CGlobal.quill[question_id].getContents(),
          }),
          material_id: id,
          page_id: MatgenGlobal.editor.curPageId,
        })
      }

      if (window.location.href.includes('editor.html')) {
        const currentUrl = window.location.href
        const url = new URL(currentUrl)
        const params = new URLSearchParams(url.search)

        params.set('material_id', id)
        params.set('study_id', study_id)

        const newUrl = `${url.origin + url.pathname}?${params.toString()}`
        history.pushState(null, '', newUrl)
        $('#study-material-next-page, #study-material-save').attr('data-id', id)
        $('#study-material-next-page, #study-material-save').attr(
          'data-study-id',
          study_id
        )
        $('#study-material-next-page, #study-material-save').trigger('click')
      } else {
        let nextPage = MatgenGlobal.EditorPage
        const opener = MatgenGlobal.getQueryParam('opener')
        if (
          redir &&
          MatgenGlobal.pages &&
          MatgenGlobal.page >= MatgenGlobal.pages.length - 1
        ) {
          if (opener && opener === 'my_materials') {
            nextPage = MatgenGlobal.MyMaterialsPage
          } else if (opener && opener === 'study_materials') {
            nextPage = MatgenGlobal.StudyMaterialsPage
          } else {
            nextPage = MatgenGlobal.MaterialSavedPage
          }
        }

        const opts = {
          tags: MatgenGlobal.answerTags,
          material_id: id,
          page_id: next_page_id,
          study_id,
        }
        if (MatgenGlobal.selectedThemeColor) {
          opts.theme_color = MatgenGlobal.selectedThemeColor.replace('#', '')
        }
        let url = `/${nextPage}${MatgenGlobal.buildQueryString(opts)}`
        // console.error('GOTO:', url);

        const template = await MatgenGlobal.Data.getTemplate(template_id)
        console.log(template)

        if (template.type === 'FILE' || template.type === 'VIDEO') {
          url = `/${
            MatgenGlobal.MaterialSavedPage
          }${MatgenGlobal.buildQueryString({
            tags: MatgenGlobal.answerTags,
            material_id: id,
          })}`
        }
        mainContentLoaderStop()
        window.location.href = url
      }
    },
  })
}

export const getFileNameFromUser = action => {
  MatgenGlobal.UI.promptModal({
    title: 'Name this material',
    label: 'Material name',
    maxChars: 256,
    action,
  })
}

const executeMaterialSave = ({
  material_id,
  study_id,
  linkURL,
  page_ga,
  ga,
  template_id,
  opener,
}) => {
  saveMaterialPage({
    id: material_id,
    template_id,
    cb: async () => {
      let materialfile
      MatgenGlobal.page++
      let url
      let nextPage = MatgenGlobal.EditorPage

      let page_id
      const opts = {
        tags: MatgenGlobal.answerTags,
      }
      if (!opener) {
        opts.opener = 'self'
      }

      if (study_id && study_id !== 'undefined') {
        opts.study_id = study_id
        const folder_opts = await authHeaderOpts()
        folder_opts.body = {
          id: study_id,
          updated: true,
        }
        await MatgenGlobal.Amplify.API.patch(
          'authenticated',
          '/user-folder',
          folder_opts
        )
      } else {
        opts.study_id = '00000000-0000-0000-0000-000000000000'
      }
      if (MatgenGlobal.page > MatgenGlobal.pages.length - 1) {
        if (opener && opener === 'my_materials') {
          nextPage = MatgenGlobal.MyMaterialsPage
        } else if (opener && opener === 'study_materials') {
          nextPage = MatgenGlobal.StudyMaterialsPage
        } else {
          nextPage = MatgenGlobal.MaterialSavedPage
        }
        opts.material_id = material_id
      } else {
        page_id = MatgenGlobal.pages[MatgenGlobal.page].id
        try {
          materialfile = await MatgenGlobal.Data.getMaterialPageFile(
            material_id,
            MatgenGlobal.pages[MatgenGlobal.page].id
          )
        } catch {
          materialfile = false
        }
      }

      opts.page_id = page_id
      opts.material_id = material_id
      if (MatgenGlobal.selectedThemeColor) {
        opts.theme_color = MatgenGlobal.selectedThemeColor.replace('#', '')
      }
      if (opener) {
        opts.opener = opener
      }
      if (materialfile && !materialfile.error && !linkURL) {
        url = `/${nextPage}${MatgenGlobal.buildQueryString(opts)}`
      } else if (!linkURL) {
        opts.template_id = MatgenGlobal.editor.templateId
        url = `/${nextPage}${MatgenGlobal.buildQueryString(opts)}`
      } else {
        url = linkURL
      }
      // console.log('GOTO:', url);
      // const name = $(e.target).attr('data-name');
      if ((!opener || opener === 'self') && page_ga) {
        const material = MatgenGlobal.Data.getMaterial(material_id)
        await pageGAEvents({ name: material.name, template_id, page_id })
      }

      if (
        (!opener || opener === 'self' || opener === 'template-picker') &&
        ga
      ) {
        const material = MatgenGlobal.Data.getMaterial(material_id)
        await materialGAEvents({ name: material.name, template_id })
      } else {
        console.log('NO GA:', opener)
      }
      // console.log(url);

      const template = await MatgenGlobal.Data.getTemplate(template_id)
      console.log(template)

      if (template.type === 'FILE' || template.type === 'VIDEO') {
        url = `/${
          MatgenGlobal.MaterialSavedPage
        }${MatgenGlobal.buildQueryString({
          tags: MatgenGlobal.answerTags,
          material_id,
        })}`
      }
      mainContentLoaderStop()
      window.location.href = url
      // console.log('URL:', url);
    },
  })
}

export const saveMaterial = async ({
  e,
  ga = false,
  page_ga = false,
  linkURL = false,
  update = true,
} = {}) => {
  let material_id = $(e.target).attr('data-id')
  const study_id = $(e.target).attr('data-study-id')
  let template_id = $(e.target).attr('data-template-id')
  const next_page_id = $(e.target).attr('data-next-page-id')
  const material = await MatgenGlobal.Data.getMaterial(material_id)
  const material_folder =
    material.user_folder_id || '00000000-0000-0000-0000-000000000000'
  const study_folder = MatgenGlobal.getQueryParam('study_id')

  if (!template_id && MatgenGlobal.template) {
    template_id = MatgenGlobal.template.id
  }

  const opener = MatgenGlobal.getQueryParam('opener')
  if (
    !material_id ||
    material_id === 'null' ||
    material_id === 'undefined' ||
    typeof material_id === 'undefined'
  ) {
    material_id = MatgenGlobal.getQueryParam('material_id')
  }

  const isFileType =
    MatgenGlobal.selectedType === 'FILE' ||
    MatgenGlobal.selectedType === 'VIDEO' ||
    (MatgenGlobal.template && MatgenGlobal.template.type === 'FILE') ||
    (MatgenGlobal.template && MatgenGlobal.template.type === 'VIDEO')

  if (
    opener !== 'editor-page' &&
    (!material_id ||
      (!material_folder && !study_folder) ||
      (Array.isArray(material) && material.length === 0))
  ) {
    mainContentLoader({ showMessage: true })
    $('#loader-message').text('Creating material...')
    createNewMaterial({ template_id, study_id, next_page_id })
  } else {
    mainContentLoader({ showMessage: true })
    $('#loader-message').text('Saving material...')
    if (material.name === 'Unnamed Material') {
      getFileNameFromUser(async name => {
        MatgenGlobal.suppressLoaderStop = true
        await saveMaterialRecord({
          name,
          id: material.id,
          template_id,
          user_folder_id: MatgenGlobal.selectedStudyId
            ? MatgenGlobal.selectedStudyId
            : material_folder || study_folder,
          update,
        })
        if (!isFileType) {
          executeMaterialSave({
            material_id,
            study_id,
            linkURL,
            page_ga,
            ga,
            template_id,
            opener,
          })
        }
      })
    } else {
      if (!isFileType) {
        executeMaterialSave({
          material_id,
          study_id,
          linkURL,
          page_ga,
          ga,
          template_id,
          opener,
        })
      }
    }
  }
}

const showReusableContentFromStudy = (
  folders,
  answers,
  userAnswers,
  study_id,
  study_name = ''
) => {
  const questionSubtext =
    answers[0].question_subtext && answers[0].question_subtext !== ''
      ? `<p>${answers[0].question_subtext}</p>`
      : ''
  return `
    <div id="reuse-text-header">
      <button id="reuse-text-back" class="back-btn btn btn-outline-primary" type="button">&lt;Back</button>
      <h3 class="color--blue hero__heading">Import from materials in:</h3>
      ${
        folders.length
          ? `<select id="user-folder-select">
        ${folders
          .map(
            a =>
              `<option value="${a.id}"${a.id === study_id ? ' selected' : ''}>${
                a.name
              }</option>`
          )
          .join('')}
          </select>
    `
          : `
          <select id="user-folder-select" disabled>
              <option value="0" selected>${study_name}</option>
          </select>
        `
      }
  </div>

  ${
    answers && answers.length > 0
      ? `
      <div class="head-and-sub-centered">
        <h5>${answers[0].question}</h5>
        ${questionSubtext}
      </div>

      <div id="reuse-text">
        <div class="d-flex">
          <div class="nav flex-column nav-tabs" id="material-tabs" role="tablist" aria-orientation="vertical">
            ${answers
              .map(
                (a, i) => `
              <button class="nav-link${i === 0 ? ' active' : ''}" id="tab-${
                a.id
              }-tab" data-bs-toggle="tab" data-bs-target="#tab-${
                a.id
              }-content" type="button" role="tab" aria-controls="tab-${
                a.id
              }-content" aria-selected="true">${
                a.material_id !== 'website' ? a.material_name : 'Website'
              }</button>
            `
              )
              .join('')}

          </div>

          <div class="tab-content" id="material-tabs-content">
            ${userAnswers
              .map(
                (a, i) => `
              <div class="tab-pane fade${
                i === 0 ? ' show active' : ''
              }" id="tab-${
                a.id
              }-content" role="tabpanel" aria-labelledby="tab-${
                a.id
              }-tab" tabindex="0">
                <div>
                  ${a.sanitized}
                </div>
                <div style="text-align:right;">
                  <button id="use-text" data-answer-id="${
                    a.id
                  }" class="btn btn-primary" type="button">Use this text</button>
                </div>
              </div>
            `
              )
              .join('')}
          </div>
        </div>
      </div>
  `
      : `
      <div id="no-material-wrapper">
          <p>
          You currently do not have any saved text for this section.</p><p>Once you have written and saved text, you will have the ability to reuse that text in future materials you design.
          </p>
      </div>
      `
  }
  `
}

const getStudyContent = async (study_id, material_id) => {
  if (!study_id || study_id === 0 || study_id === '0') {
    study_id = '00000000-0000-0000-0000-000000000000'
  }
  const opts = await authHeaderOpts()
  const matId = material_id ? `&material_id=${material_id}` : ''
  const answers = await MatgenGlobal.Amplify.API.get(
    'authenticated',
    `/user-content?question_id=${
      MatgenGlobal.richTextObj.studyDataConnection
    }&type=import&user_folder_id=${
      study_id || '00000000-0000-0000-0000-000000000000'
    }${matId}`,
    opts
  )

  const other_studies = await MatgenGlobal.Amplify.API.get(
    'authenticated',
    `/user-content?question_id=${
      MatgenGlobal.richTextObj.studyDataConnection
    }&type=other_studies&user_folder_id=${
      study_id && study_id !== 0
        ? study_id
        : '00000000-0000-0000-0000-000000000000'
    }`,
    opts
  )

  const userAnswers = answers.map(a => {
    let sanitized = false
    try {
      const answer = JSON.parse(a.answer)
      sanitized = DOMPurify.sanitize(answer.html)
    } catch {
      sanitized = false
    }
    return { id: a.id, sanitized }
  })
  const folders = []
  const newFolders = []
  for (let i = 0; i < other_studies.length; i++) {
    if (!newFolders.find(f => f.id === other_studies[i].user_folder_id)) {
      folders.push({
        id: other_studies[i].user_folder_id,
        name: other_studies[i].folder_name,
      })
      newFolders.push({
        id: other_studies[i].user_folder_id,
        name: other_studies[i].folder_name,
      })
    }
  }

  if (answers.length === 0 && folders.find(f => f.id === study_id)) {
    folders.splice(
      folders.findIndex(f => f.id === study_id),
      1
    )
  }
  return { answers, userAnswers, folders }
}

// Constants
const MODAL_IDS = {
  RICH_TEXT: 'editor-rich-text-modal',
  TUTORIAL: 'editor-tutorial-text-modal',
}

// Helper functions
const getMaterialData = async material_id => {
  if (!material_id) {
    return null
  }

  const material = await MatgenGlobal.Data.getMaterial(material_id)
  if (!material?.error && Array.isArray(material)) {
    return material[0]
  }
  return material?.error ? null : material
}

const fetchQuestionData = async (questionId, study_id) => {
  const opts = await authHeaderOpts()

  let question = await MatgenGlobal.Amplify.API.get(
    'public',
    `/questions/${questionId}`,
    opts
  )

  if (Array.isArray(question)) {
    question = question[0]
  }

  if (study_id) {
    const userContent = await MatgenGlobal.Amplify.API.get(
      'authenticated',
      `/user-content/${study_id}?question_id=${questionId}&type=editor`,
      opts
    )

    if (Array.isArray(userContent)) {
      question = userContent[0]
    }
  }

  return question
}

const formatQuestionData = async questionData => {
  const examples = await MatgenGlobal.Data.API.request(
    '/question-examples',
    'POST',
    { ids: [questionData.id] }
  )

  return {
    id: questionData.id,
    component: questionData.component,
    text: questionData.text,
    sub_text: questionData.sub_text,
    instructions: questionData.instructions,
    insert_sub_text: questionData.insert_sub_text,
    answers: [
      {
        id: questionData.answer_id,
        text: questionData.answer_text,
        sub_text: questionData.answer_sub_text,
        sort_order: questionData.answer_order,
      },
    ],
    examples,
  }
}

const checkRequired = (question, study_id) => {
  if (
    !study_id ||
    !isJsonAnswer(question.component) ||
    !question.answers[0].text
  ) {
    return false
  }

  try {
    const json = JSON.parse(question.answers[0].text)
    return Boolean(json.required)
  } catch {
    console.error('Bad answer JSON:', question)
    return false
  }
}

const initializeRichTextEditor = async question => {
  MatgenGlobal.RichtextExitSelector = '#rich-text-update'
  window.rte = M4CRichTextEditor

  MatgenGlobal.currentRTE = new M4CRichTextEditor({
    id: question.id,
    changeHandler: () => {
      $('#rich-text-update').attr('disabled', true).css('cursor', 'wait')

      document.querySelector('#quill-output').innerHTML =
        M4CGlobal.quill[question.id].root.innerHTML

      debouncedrichTextEditorUpdate({ id: question.id })
    },
  })

  await initQuillOutput()
  try {
    await loadRichTextFonts()
  } catch (err) {
    console.error('Error loading rich text fonts:', err)
  }
}

const setupQuillOutput = () => {
  $('#quill-output-wrapper').remove()
  $('body').append(
    $('<div id="quill-output-wrapper"><div id="quill-output" /></div>')
  )
}

const restoreQuillData = (questionId, quillData) => {
  if (!quillData) {
    return
  }

  MatgenGlobal.saveRichTextRestore = quillData
  M4CGlobal.quill[questionId].setContents(JSON.parse(quillData))
  document.querySelector('#quill-output').innerHTML =
    M4CGlobal.quill[questionId].root.innerHTML
  debouncedrichTextEditorUpdate({ id: questionId })
}

const getTutorialContent = () => {
  return `
    <div class="wrapping">
      <img src="/assets/img/editor/pointers@2x.png" alt="Image highlighting the preview tab which shows how text that is too long will get cut off.">
      <ul>
        <li>
        Content areas are <strong>fixed sizes</strong> and cannot be changed.
        </li>
        <li>
        Use <strong>Preview</strong> when you edit your text to make sure it fits within each section.
        </li>
        <li>
        Don't have enough room? <strong>Edit your text or explore other templates</strong> that offer different content area sizes.
        </li>
      </ul>

      <button class="btn btn-primary" id="tutorial-accept" tabindex=0>OK</button>
      <p><label for="dont-show-again"><input type="checkbox" id="dont-show-again"> Don't show me this again</label></p>
    </div>
    `
}

const showTutorialIfNeeded = () => {
  if (
    sessionStorage.getItem('dontShowTutorial') !== null ||
    localStorage.getItem('dontShowTutorial') !== null
  ) {
    return
  }

  MatgenGlobal.tutorialPopup = true
  MatgenGlobal.M4CModal.show({
    id: MODAL_IDS.TUTORIAL,
    title: 'Why should I use Preview?',
    content: getTutorialContent(),
    width: '600px',
  })

  sessionStorage.setItem('dontShowTutorial', true)
}

const init = () => {
  if (!MatgenGlobal.editorPanelEventsInitialized) {
    $(document).on('matgen-color-picked', e => {
      // console.error('COLOR PICKED:', e.detail);
      if (e.detail.id === 'editor') {
        if (!M4CGlobal.pickr.editor) {
          return false
        }
        const color = M4CGlobal.pickr.editor
          .getColor()
          .toHEXA()
          .toString()
          .substring(0, 7)

        const objects = MatgenGlobal.editor.cur().fabric.getObjects()
        MatgenGlobal.selectedThemeColor = color
        MatgenGlobal.editor.cur().fabric.themeColor = e.detail.color
        if (e.detail.curObj) {
          let opacity = e.detail.curObj.fill.substring(7, 9)
          if (opacity === '') {
            opacity = 'FF'
          }
          e.detail.curObj.set({ fill: `${color}${opacity}` })
        }
        for (let i = 0; i < objects.length; i++) {
          if (objects[i].useThemeColor) {
            let opacity = objects[i].fill.substring(7, 9)
            if (opacity === '') {
              opacity = 'FF'
            }

            if (objects[i].richText && !MatgenGlobal.notStudy) {
              MatgenGlobal.UI.changeRichTextColor(objects[i], color, opacity)
            } else {
              objects[i].set({ fill: `${color}${opacity}` })
              objects[i].set({ styles: [] })
            }
          }
        }

        MatgenGlobal.editor.cur().fabric.renderAll()
      }
    })

    $(document).on('matgen-event-loader-stop', e => {
      if (
        e.detail.promises.find(
          a => a.group === 'build-sidebar' && a.fulfilled === true
        )
      ) {
        window.setTimeout(() => {
          MatgenGlobal.EditorPanel.loader.stop()
          $('#editor-container').css({
            filter: 'initial',
          })
        }, 250)
      }
    })

    $(document).on('click', '#tutorial-accept', () => {
      richTextEditorPreview()
      $('#editor-tutorial-text-modal').modal('toggle')
      $('#editor-tutorial-text-modal').focus()

      sessionStorage.setItem('dontShowTutorial', true)

      if ($('#dont-show-again').is(':checked')) {
        localStorage.setItem('dontShowTutorial', true)
      }
    })

    $(document).on('matgen-event-loader-start', e => {
      // console.error('LOADER START:', e.detail);
      if (
        e.detail.p.group === 'load-options' ||
        e.detail.p.group === 'load-sidebar'
      ) {
        if (!MatgenGlobal.EditorDisplayed) {
          $('#matgen-inner .skeleton-loader-container').remove()
          // mainContentLoaderStop();
          $('#editor-container').css({
            position: 'initial',
            visibility: 'visible',
            transition: 'grayscale 2s, brightness 2s, blur 2s',
          })
          // $('#loader-message').hide();
          $('#editor-container').css(
            'filter',
            'grayscale(100%) brightness(1.12) blur(3px)'
          )
          MatgenGlobal.EditorDisplayed = true
        }
      }
    })

    $(document).on('matgen-event-richtext-modal', async e => {
      try {
        // Initial setup
        MatgenGlobal.richTextObj = e.detail
        $('#matgen-sidebar').css('opacity', 0)
        mainContentLoader({ showMessage: true })
        $('#loader-message').text('Loading rich text editor...')
        $(`#${MODAL_IDS.RICH_TEXT}`).remove()

        // Get study and material data
        const material_id = MatgenGlobal.getQueryParam('material_id')
        let study_id = MatgenGlobal.getQueryParam('study_id')

        const material = await getMaterialData(material_id)
        if (material) {
          study_id = material.user_folder_id
        }

        if (!MatgenGlobal.richTextObj.studyDataConnection) {
          mainContentLoaderStop()
          return
        }

        // Fetch and process question data
        const rawQuestion = await fetchQuestionData(
          MatgenGlobal.richTextObj.studyDataConnection,
          study_id
        )

        if (!rawQuestion) {
          MatgenGlobal.UI.handleError(
            'Question not found.',
            'The data question attached to this element could not be found. Inform support that this template is misconfigured.'
          )
          mainContentLoaderStop()
          return false
        }

        const question = await formatQuestionData(rawQuestion)
        const required = checkRequired(question, study_id)

        const creds =
          await MatgenGlobal.Amplify.Auth.currentUserCredentials().catch(
            () => ({ authenticated: false })
          )

        // Setup rich text editor
        mainContentLoaderStop()

        MatgenGlobal.M4CModal.show({
          id: MODAL_IDS.RICH_TEXT,
          content: richTextContainer(
            question.text,
            question.sub_text,
            required,
            RichTextForm(question, true, creds.authenticated),
            question.id
          ),
          width: '1200px',
          hidden: true,
        })

        $(`#${MODAL_IDS.RICH_TEXT}`).attr('data-question-id', question.id)

        await initializeRichTextEditor(question)
        setupQuillOutput()
        restoreQuillData(question.id, MatgenGlobal.richTextObj.quillData)
        showTutorialIfNeeded()
      } catch (error) {
        console.error('Error in rich text modal:', error)
        mainContentLoaderStop()
      }
    })

    $(document).on('click', '#preview-tab', () => {
      richTextEditorPreview()
    })

    $(document).on('click', '.page-back', e => {
      e.preventDefault()

      MatgenGlobal.M4CModal.show({
        id: 'save-page-modal',
        title: 'save changes',
        content:
          'Would you like to save changes made to this page (if any) before switching pages?',
        buttons: [
          {
            id: 'save-page-modal-cancel-button',
            classname: 'secondary btn btn-secondary',
            label: 'Cancel',
          },
          {
            id: 'save-page-modal-no-button',
            classname: 'secondary btn btn-secondary',
            label: 'No',
          },
          {
            id: 'save-page-modal-yes-button',
            classname: 'primary btn btn-primary',
            label: 'Yes, Save Changes',
          },
        ],
        closeButton: false,
      })

      $('#save-page-modal-cancel-button').off('click')
      $('#save-page-modal-cancel-button').on('click', () => {
        $('#save-page-modal').modal('toggle')
      })

      $('#save-page-modal-no-button').off('click')
      $('#save-page-modal-no-button').on('click', () => {
        $('#save-page-modal').modal('toggle')
        window.location.href = $(e.target).attr('href')
      })

      $('#save-page-modal-yes-button').off('click')
      $('#save-page-modal-yes-button').on('click', () => {
        $('#save-page-modal').modal('toggle')
        const linkURL = $(e.target).attr('href')
        saveMaterial({ e, linkURL, page_ga: true, update: true })
      })
    })

    $(document).on('click keypress', '#cancel-rich-text', e => {
      if (MatgenGlobal.UI.a11yClick(e) === true) {
        e.preventDefault()
        $('#editor-rich-text-modal .close').click()
      }
    })

    MatgenGlobal.isHiddenEventTriggered = false

    $(document).on('click keypress', '.ql-editor', () => {
      MatgenGlobal.changed = true
    })

    $(document).on('hide.bs.modal', '#editor-rich-text-modal', e => {
      if (MatgenGlobal.changed && !MatgenGlobal.isHiddenEventTriggered) {
        e.preventDefault()
        MatgenGlobal.isHiddenEventTriggered = true
        MatgenGlobal.M4CModal.show({
          id: 'save-page-modal',
          title: 'save changes?',
          content: 'Would you like to save your changes?',
          buttons: [
            {
              id: 'save-page-modal-cancel-button',
              classname: 'secondary btn btn-secondary',
              label: 'Cancel',
            },
            {
              id: 'save-page-modal-no-button',
              classname: 'secondary btn btn-secondary',
              label: 'No',
            },
            {
              id: 'save-page-modal-yes-button',
              classname: 'primary btn btn-primary',
              label: 'Yes, Save Changes',
            },
          ],
          closeButton: false,
        })

        $('#save-page-modal-cancel-button').off('click')
        $('#save-page-modal-cancel-button').on('click', () => {
          killModal('#save-page-modal')
          MatgenGlobal.isHiddenEventTriggered = false
        })

        $('#save-page-modal .close').off('click')
        $('#save-page-modal .close').on('click', () => {
          killModal('#save-page-modal')
          MatgenGlobal.isHiddenEventTriggered = false
        })

        $('#save-page-modal-no-button').off('click')
        $('#save-page-modal-no-button').on('click', () => {
          $('#save-page-modal').modal('hide')
          if (!MatgenGlobal.RTCommitted && MatgenGlobal.saveRichTextRestore) {
            cancelRichText(
              $('#editor-rich-text-modal').attr('data-question-id')
            )
          } else {
            $('#editor-rich-text-modal').trigger('hidden.bs.modal')
          }
        })

        $('#save-page-modal-yes-button').off('click')
        $('#save-page-modal-yes-button').on('click', () => {
          $('#save-page-modal').modal('hide')
          $('#editor-rich-text-modal').trigger('hidden.bs.modal')
        })
      }
    })

    $(document).on('hidden.bs.modal', '#editor-rich-text-modal', () => {
      MatgenGlobal.isHiddenEventTriggered = false
      MatgenGlobal.changed = false
      $('#matgen-sidebar').css('opacity', 1)
      if (MatgenGlobal?.richTextObj?.id) {
        $(`#${MatgenGlobal.richTextObj.id}`).focus()
        delete MatgenGlobal.richTextObj
      }
    })

    $(document).on('hidden.bs.modal', '#content-select-modal', () => {
      richTextLoaderStop()
    })

    $(document).on('show.bs.modal', '#prompt-form-modal', () => {
      $('#prompt-form-modal').css('z-index', 2147483647)
    })

    $(document).on('hidden.bs.modal', '#prompt-form-modal', () => {
      if (!MatgenGlobal.promptSaveClicked) {
        richTextLoaderStop()
      }
    })

    const rteCb = () => {
      window.setTimeout(() => {
        if (!opener) {
          const searchParams = new URLSearchParams(window.location.search)
          searchParams.set('opener', 'self')
          window.location.search = searchParams.toString()
          window.location.reload()
        }
      }, 250)
    }

    $(document).on('click keypress', '#rich-text-update', async e => {
      if (MatgenGlobal.UI.a11yClick(e) === true) {
        MatgenGlobal.RTCommitted = true
        e.preventDefault()
        const uc_id = $(e.target).attr('data-uc-id')
        const question_id = $(e.target).attr('data-question-id')
        const material_id = MatgenGlobal.getQueryParam('material_id')
        const template_id = MatgenGlobal.getQueryParam('template_id')
        let study_id = MatgenGlobal.getQueryParam('study_id')
        const id = uc_id || UUID()
        richTextLoader()
        if (!material_id && template_id && study_id) {
          $('#loader-message').text('Creating material...')
          createNewMaterial({
            study_id,
            template_id,
            question_id,
            redir: false,
          })
        } else if (material_id) {
          let material = await MatgenGlobal.Data.getMaterial(material_id)
          if (!material || material.error) {
            MatgenGlobal.UI.handleError(
              'Error retrieving material',
              'There was a problem finding your material. Please try again later or contact support.'
            )
          }
          if (material && !material.error && Array.isArray(material)) {
            material = material[0]
          }
          if (material && !material.error) {
            study_id = material.user_folder_id
          }
          $('#loader-message').text('Saving material...')
          richTextLoaderStop()
          // console.log('si', study_id);
          if (study_id) {
            await saveStudyData({
              id,
              user_folder_id: study_id,
              question_id,
              answer: JSON.stringify({
                html: M4CGlobal.quill[question_id].root.innerHTML,
                plain: M4CGlobal.quill[question_id].getText(),
                data: M4CGlobal.quill[question_id].getContents(),
              }),
              material_id,
              page_id: MatgenGlobal.editor.curPageId,
            })

            $('#loader-message').text('Saving material page...')
            saveMaterialPage({
              id: material_id,
              study_id,
              template_id: material.template_id,
              cb: () => {
                debouncedrichTextEditorUpdate({
                  id: question_id,
                  cb: rteCb,
                })
              },
            })
          } else {
            richTextLoaderStop()
            MatgenGlobal.isHiddenEventTriggered = true
            $('#editor-rich-text-modal').modal('toggle')
          }
        }
      }
    })

    $(document).on('change', '#user-folder-select', async e => {
      const material_id = MatgenGlobal.getQueryParam('material_id')
      const study_id = $(e.target).val()

      $('#content-select-modal .modal-body')
        .empty()
        .append(
          $(`
        <div id="main-loader-wrapper">
          <div id="main-loader-target" style="height:100%;max-height:80vh;width:100%;position:absolute;top:0;left:0;z-index:2500;"><div id="preload-content-loader" class="section-loader">
            <div class="loader-grid"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            <div id="loader-message" class="badge rounded-pill">Loading study data...</div>
          </div></div>
        </div>
        `)
        )

      const { answers, userAnswers, folders } = await getStudyContent(
        study_id,
        material_id
      )
      const study_name = $('#material-name-edit').html()

      MatgenGlobal.currentAnswers = answers
      $('#content-select-modal .modal-body')
        .empty()
        .append(
          $(
            showReusableContentFromStudy(
              folders,
              answers,
              userAnswers,
              study_id,
              study_name
            )
          )
        )
    })

    $(document).on('click keypress', '#reuse-my-text', async e => {
      if (MatgenGlobal.UI.a11yClick(e) === true) {
        e.preventDefault()
        richTextLoader()
        const study_id = MatgenGlobal.getQueryParam('study_id')
        const material_id = MatgenGlobal.getQueryParam('material_id')
        const { answers, userAnswers, folders } = await getStudyContent(
          study_id,
          material_id
        )

        const study_name = $('#material-name-edit').html()

        MatgenGlobal.currentAnswers = answers
        MatgenGlobal.M4CModal.show({
          id: 'content-select-modal',
          content: showReusableContentFromStudy(
            folders,
            answers,
            userAnswers,
            study_id,
            study_name
          ),
        })
        if (answers.length === 0) {
          $('#user-folder-select').trigger('change')
        }
      }
    })

    $(document).on('click keypress', '#use-examples', e => {
      if (MatgenGlobal.UI.a11yClick(e) === true) {
        e.preventDefault()
        $('#preview-tab').removeClass('active')
        $('#examples-tab').addClass('active')
        $('#preview-tab-pane').removeClass('show')
        $('#preview-tab-pane').removeClass('active')
        $('#examples-tab-pane').addClass('show')
        $('#examples-tab-pane').addClass('active')
      }
    })

    $(document).on('click keypress', '#use-text', e => {
      if (MatgenGlobal.UI.a11yClick(e) === true) {
        e.preventDefault()

        const answer = MatgenGlobal.currentAnswers.find(
          a => a.id === $(e.target).attr('data-answer-id')
        )
        let rtData
        // console.error('ANSWER:', answer);
        try {
          rtData = JSON.parse(answer.answer)
        } catch {
          rtData = false
        }

        if (rtData) {
          // console.error('rtData:', rtData);

          M4CGlobal.quill[answer.question_id].setContents(rtData.data)
          document.querySelector('#quill-output').innerHTML =
            M4CGlobal.quill[answer.question_id].root.innerHTML
          debouncedrichTextEditorUpdate({
            id: answer.question_id,
            cb: () => {
              richTextLoaderStop()
              killModal('#content-select-modal')
            },
          })
        }
      }
    })

    $(document).on('click keypress', '#reuse-text-back', e => {
      if (MatgenGlobal.UI.a11yClick(e) === true) {
        e.preventDefault()
        richTextLoaderStop()
        killModal('#content-select-modal')
      }
    })

    $(document).on(
      'click keypress',
      '#study-material-next-page, #study-material-save',
      async e => {
        if (MatgenGlobal.UI.a11yClick(e) === true) {
          e.preventDefault()
          MatgenGlobal.clickAfterLogin = $(e.currentTarget).attr('id')
          const authorized = await requireAuth()
          if (authorized) {
            const material = await MatgenGlobal.Data.getMaterial(
              MatgenGlobal.getQueryParam('material_id')
            )
            if (
              MatgenGlobal.getQueryParam('opener') === 'editor-page' ||
              MatgenGlobal.getQueryParam('study_id') ||
              material.name !== 'Unnamed Material'
            ) {
              richTextLoaderStop()
              saveMaterial({ e, page_ga: true, ga: true })
            } else {
              studySelect(
                $(e.target).attr('data-id'),
                MatgenGlobal.editor.templateId
              )
              await loadStudySelect()
            }
          }
        }
      }
    )

    $(document).on('matgen-event-option-selected', e => {
      if (
        e.detail.curObj.useThemeColor &&
        (MatgenGlobal.selectedThemeColor ||
          MatgenGlobal.editor.cur().fabric.themeColor)
      ) {
        document.dispatchEvent(
          new CustomEvent('matgen-color-picked', {
            detail: {
              id: 'editor',
              color: M4CGlobal.pickr.editor.getColor().toHEXA().toString(),
              curObj: e.detail.curObj,
            },
          })
        )
      }
    })
    $(document).on('keyup', '.pcr-button', e => {
      if (e.key === 'Enter' || e.keyCode === 13) {
        $('.pcr-result').focus()
        $('.pcr-save').keydown(k => {
          if (k.which === 9 || k.which === 'Enter' || k.which === 13) {
            e.preventDefault()
            $('.pcr-save').trigger('click')
            $('#material-name-edit').focus()
          }
        })
      }
    })

    $(document).on('click', '.pcr-save', () => {
      if (M4CGlobal.pickr.editor) {
        let colors = [
          '#3FA28C',
          '#3C708E',
          '#876E1D',
          '#C35418',
          '#D62829',
          '#003047',
          '#587D31',
        ]

        if (sessionStorage.getItem('recentColors')) {
          const recent = JSON.parse(sessionStorage.getItem('recentColors'))
          $.merge(colors, recent)

          colors = [...new Set(colors)]
        }

        M4CGlobal.pickr.editor.setSwatches(colors)
      }
    })

    document.onmouseover = function () {
      // User's mouse is inside the page.
      window.innerDocClick = true
    }

    document.onmouseleave = function () {
      // User's mouse has left the page.
      window.innerDocClick = false
    }

    history.navigationMode = 'compatible'
    window.addEventListener('pageshow', e => {
      if (e.persisted) {
        window.location.reload()
      }
    })

    MatgenGlobal.editorPanelEventsInitialized = true
  }
}

export default init
